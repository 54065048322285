import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";

const BeaconTable = (props) => {
  const tableColumns = [
    {
      label: "Name",
      field: "name",
      sort: "asc",
      width: 25,
    },
    {
      label: "Value",
      field: "value",
      sort: "asc",
      width: 25,
    },
  ];

  // const [tableRows, setTableRows] = useState([]);
  const [tableData, setTableData] = useState({
    columns: [tableColumns],
    rows: [],
  });

  useEffect(() => {
    processBeacon(props.parserText);
  }, [props.parserText]);

  const processBeacon = (parserText) => {
    if (typeof parserText !== "undefined") {
      console.log("processBeacon:", parserText);
      let textareaContent = parserText.replace(/\+/g, " ");
      let beacons = [];
      let content;

      if (textareaContent.includes("%@")) {
        content = textareaContent.split("%@");
      } else {
        content = textareaContent.split("\n");
      }

      for (var item of content) {
        item = item.trim();
        if (item.length > 0) {
          item = decodeURIComponent(item).replace(/\s&\s/g, "spaceandspace");
          beacons.push(item.split("&"));
        }
      }

      let tableRows = [];
      for (var beacon of beacons) {
        beacon.sort();

        for (var item of beacon) {
          // console.log(item);
          item = item.replace(/spaceandspace/g, " & ");

          let item_row = item.split("=");
          let property_name = item_row[0];
          let property_value;
          if (
            /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/.test(item_row[1]) &&
            item_row[1].includes("e+")
          ) {
            property_value = Number(item_row[1]) + " (" + item_row[1] + ")";
          } else {
            property_value = item_row[1];
          }
          if (property_value && property_value.includes("+")) {
            property_value = property_value.replace(/\+/g, " ");
          }

          tableRows.push({
            name: property_name,
            value: property_value,
          });
        }
      }
      setTableData({
        columns: tableColumns,
        rows: tableRows,
      });
    }
  };

  return (
    <div
      className="d-flex p-2 col-example"
      style={
        {
          // backgroundColor: "lightgray",
          // width: "300px",
        }
      }
    >
      <MDBDataTable
        paging={false}
        striped
        bordered
        responsive
        scrollX
        data={tableData}
      />
    </div>
  );
};

export default BeaconTable;
