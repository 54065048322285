import React, { useState, useRef } from "react";
import BeaconTable from "./components/BeaconTable.js";
import "./App.css";

function App() {
  const [parserText, setParserText] = useState();
  const [beaconTables, setBeaconTables] = useState([]);

  const textAreaRef = useRef();
  const handleSubmit = () => {
    console.log(textAreaRef.current.value);
    setParserText(textAreaRef.current.value);

    const copiedArray = Array.from(beaconTables);
    copiedArray.push(<BeaconTable parserText={textAreaRef.current.value} />);
    setBeaconTables(copiedArray);

    textAreaRef.current.value = "";
  };
  const handleReset = () => {
    // console.log(textAreaRef.current);
    textAreaRef.current.value = "";
    setParserText("");
    setBeaconTables([]);
  };

  return (
    <React.Fragment>
      <div className="container">
        <img src="./brillio_logo.svg" alt="" />
        <h1>ANALYTICS BEACON PARSER</h1>
        <div>
          <textarea
            ref={textAreaRef}
            name="parserText"
            id="textarea-input"
            rows="13"
            onChange={(e) => {
              // setParserText(e.target.value);
            }}
          ></textarea>
        </div>
        <div id="submit-btn">
          <input
            id="submit"
            type="submit"
            value="Add Beacon"
            onClick={handleSubmit}
          />
          <button id="reset" onClick={handleReset}>
            Reset
          </button>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "stretch",
          }}
        >
          {beaconTables}
        </div>
      </div>
    </React.Fragment>
  );
}

export default App;
